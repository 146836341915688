import '../App.css';
import Header  from '../Header';

function CloseMe() {

  return (
      <div>
       
        <Header showHeader="0" />
        
        <div id="infoPage">

          <center>

          <br/><br/><br/>
          
          <h2>
            Close this tab and find
            the one you opened previously.
          </h2>

          <br/><br/><br/>
          
          </center>
        
          
          
        </div>
      </div>

  );
}

export default CloseMe;
