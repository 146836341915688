import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Home from './components/Home';
import SketchFabAuth from './components/SketchFabAuth';
import IdeaEngine from './components/IdeaEngine';
import NotFound from './components/NotFound';
import Videos from './components/Videos';
import Video from './components/Video';
import RPM from './components/RPM';
import Privacy from './components/Privacy';
import XRPrivacy from './components/xr_privacy';
import XRTerms from './components/xr_terms';
import Assets from './components/assets';
import Contact from './components/contact';
import FastHands from './components/fasthands';
import CloseMe from './components/closeme';



function App() {

  ReactGA.initialize('G-9WV175H1W9');
  
  const externalRedirectPath = '/skf';
  const history = useHistory();
  useEffect(() => {
    // Check if the current path matches the route for external redirection
    if (history.location.pathname === "/skf") {
      // Replace with your external URL
      const externalURL = 'https://sketchfab.com/oauth2/authorize/?state=123456789&response_type=token&client_id=RqbvcxSd0ypxCDYTHMZoitSVH7laWA1NjFaai15Z';
      window.location.href = externalURL;
    }
    const isWWW = window.location.hostname.toLowerCase().startsWith('www');
    const isLocal = window.location.hostname.toLowerCase().startsWith('localhost');
    if (!isWWW && !isLocal)
      window.location.href = `https://www.${window.location.hostname}${window.location.pathname}`;

  }, [history]);

   
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/alpha" component={IdeaEngine} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/aboutUs" component={Contact} />
          <Route path="/xr_privacy" component={XRPrivacy} />
          <Route path="/xr_terms" component={XRTerms} />
          <Route path="/videos" component={Videos} />
          <Route path="/rpm" component={RPM} />
          <Route path="/video/:videoId" component={Video} />
          <Route path="/ideaengine" component={IdeaEngine} />
          <Route path="/sf-auth" component={SketchFabAuth} />
          <Route path="/issues" component={Contact} />
          <Route path="/contact" component={Contact} />
          <Route path="/fasthands" component={FastHands} />
          <Route path="/closeme" component={CloseMe} />
          <Route path="/assets" component={Assets} />
          <Route exact path="/" component={Home} />
          <Route path={externalRedirectPath} />
          <Route component={NotFound} />
          
        </Switch>
      </Router>
    </div>

    );
    
}

export default App;
