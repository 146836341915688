import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function Privacy() {
  
  return (
      <div>
       
        <Header showHeader="1" />
        
        <NavBar path="/xr_terms" />

        <div id="infoPage">

          <h2>Idea Engine - Terms</h2>
          <p>Updated 11 September 2023</p>
        
          <p>The term &#39;us&#39;, &#39;our&#39; or &#39;we&#39; refers to the owner of the software trading as X82 Ltd. The term &#39;you&#39; refers to the customer / user of our software.</p>
          <p>We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
          
          <div>
            <h3>Use of Licensed Assets</h3>
            <p>We are committed to respecting the intellectual property rights of others and ensuring that all assets used in the software are properly licensed. This includes assets released under various Creative Commons licenses, such as CC0 (Public Domain Dedication) and CC-BY (Attribution)</p>.
            <p>CC0 (Public Domain Dedication): Assets licensed under CC0 are considered to be in the public domain and can be used, modified, and distributed without restrictions. While you may use CC0 assets in your project, we respect the rights of the creators and attribute the work when required by the license.</p>
            <p>CC-BY (Attribution): Assets licensed under CC-BY require attribution to the original creator. When you use CC-BY assets in your project, ensure proper attribution is specified in the license page. This is done automatically for Stechfab downloads within the software.</p>
            <p>We take care to ensure that all assets used in the software comply with their respective licenses, and we do not allow assets that are restricted by licenses that do not permit their use in commercial products.</p>
            <p>If you believe that we have inadvertently used an asset in violation of its license terms, please contact us immediately at support@x82hub.com, and we will promptly address the issue. We are committed to resolving any such concerns in a timely and appropriate manner.</p>
          </div>

          <div>
            <h3>Prohibition of Offensive Content</h3>
            <p>We are committed to providing a safe and enjoyable environment for all players. To maintain a positive experience, we prohibit the creation, sharing, or distribution of offensive content, which includes but is not limited to:</p>
            <p>Hate Speech: Any content that promotes discrimination, harassment, or violence based on race, ethnicity, religion, gender, sexual orientation, disability, or any other protected characteristic is strictly prohibited.</p>
            <p>Harassment and Bullying: We do not tolerate any form of harassment or bullying, including verbal abuse, threats, or intimidation directed at other players.</p>
            <p>Obscene or Inappropriate Material: Content that is sexually explicit, pornographic, or contains graphic violence is not allowed.</p>
            <p>Personal Information: Sharing personal information about yourself or others, including addresses, phone numbers, and social media profiles, without consent is prohibited.</p>
            <p>Cheating and Exploits: Exploiting bugs, using unauthorized third-party software, or engaging in any form of cheating is against our terms of service.</p>
            <p>Impersonation: Impersonating other players, moderators, or staff members is not allowed.</p>
            <p>Invasion of Privacy: Any activity that invades the privacy of other players, including stalking or doxxing (revealing personal information), is strictly prohibited.</p>
            <p>Inflammatory Content: Content that is deliberately designed to incite anger, hatred, or conflict among players is not permitted.</p>        
            <p>We encourage all players to report offensive content or behavior they encounter in the software. Reports can be made through the in-app reporting system or by contacting our support team at support@x82hub.com.</p>
          </div>

          <div>
            <p>We reserve the right to take appropriate action against players who violate these rules, including permanent banning from the software. We may also report serious violations to law enforcement if necessary.</p>
            <p>By using our software, you agree to abide by these rules and help us create a respectful and inclusive community.</p>
          </div>
            
          </div>
        <br/>
        <Footer />
      </div>

  );
}

export default Privacy;
