import React, { useState, useEffect } from 'react';
import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';
import { Helmet } from 'react-helmet';


declare global {
  interface Window {
    amp: any; // Change `any` to the actual type definition of the `amp` library
  }
}

function Videos() {



  // Function to handle video source changes
  const handleVideoSourceChange = (source: string) => {
    var p = window.amp("vid2");
    p.src([{
      src: source,
      type: "application/vnd.ms-sstr+xml"
    }]);
    p.play();
    if (windowWidth < 800)
      setIsExpanded(false);


  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsExpanded(windowWidth >= 800);

    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);    

  useEffect(() => {
    setIsExpanded(windowWidth >= 800);
  }, [windowWidth]);
  
  return (
      <div>
        <Helmet>
          <link rel="stylesheet" href="//amp.azure.net/libs/amp/2.3.11/skins/amp-default/azuremediaplayer.min.css" type="text/css" />
          <script src= "//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js" type="text/javascript"/>
        </Helmet>     

        <Header showHeader="1" />

        <div id="thePage">

          <NavBar path="/videos" />
            <h2>Idea Engine - dev videos</h2>    
            <div className="flex-container">
              <div className="flex-item pill1">

              <p>
                <div>
                  <b>
                  For tutorials please visit our
                  <a href="https://youtube.com/playlist?list=PLuy0o1eHIdSR2wb80SeEqNkQK4_STlShG&si=MKUuDCdAzbhymH13">Tutorials Playlist</a>
                  </b>
                  <hr/>
                </div>
              </p>


                {windowWidth < 800 && (
                  <button className="rightBtn myBtn" onClick={handleToggle}>
                    {isExpanded ? 'less' : 'more'}
                  </button>
                )}
                <div id="videoList">
                  {isExpanded ? (
                    <>


                    
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/602d59e9-0d09-4a18-bddb-4b9d7437c9b9/IdeaEngineAlphaTrailerV2.ism/manifest(format=m3u8-cmaf)')}>
                      Trailer</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/39ab13db-b2c8-4848-8a27-6cf3e28f2c68/sample editing interactions.ism/manifest(format=m3u8-cmaf)')}>
                      Sample editing interactions</p>
                      <p onClick={() => handleVideoSourceChange("//x82video-usea.streaming.media.azure.net/e919bfec-8223-4729-93bd-f925f73cdcca/Idea Engine's inventory system i.ism/manifest(format=m3u8-cmaf)")}>
                      Inbuilt inventory system</p>                         
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/5a966937-b490-4c87-adf2-46d0a0ba3643/lipsync - Trim.ism/manifest(format=m3u8-cmaf)')}>
                      Cartoon lipsync</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/73881b07-b3a5-48cc-b4d8-de5ba3a25af8/webIntro.ism/manifest(format=m3u8-cmaf)')}>
                      Creating an event</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/6c693423-2c26-41a9-9de2-60430fd0b2de/sketchfab - Trim.ism/manifest(format=m3u8-cmaf)')}>
                      Sketchfab integration</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/fda32b97-fad4-493d-8b97-b9b39bc8cf67/statesTW.ism/manifest(format=m3u8-cmaf)')}>
                      Nodes can have multiple states</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/1e6d7074-3900-4b94-81f5-fb11d6cbc4d4/animatedIconsTrim.ism/manifest(format=m3u8-cmaf)')}>
                      850+ scalable icons included</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/2c2c508c-b671-492d-8580-1ab7cdc6a176/AITest2  .ism/manifest(format=m3u8-cmaf)')}>
                      AI text and image generation</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/6bad52cb-4315-43af-9504-3e536839240b/flipbookTrimed.ism/manifest(format=m3u8-cmaf)')}>
                      Flipbook animations</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/3c56b28a-4582-4176-8053-269129ab6582/voices.ism/manifest(format=m3u8-cmaf)')}>
                      Generate voices for your characters</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/461c05bc-0761-45ea-8df2-1a2ebbef80fe/Basket.ism/manifest(format=m3u8-cmaf)')}>
                      AI character config</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/b302c79a-dfcf-4362-b76a-d2ff4f4faa14/RockPaperSissorsTwitter.ism/manifest(format=m3u8-cmaf)')}>
                      Use your photos in a game</p>
                      <p onClick={() => handleVideoSourceChange('//x82video-usea.streaming.media.azure.net/a02ce1be-05b3-485a-b31d-578d53a869c4/LightingYouTube.ism/manifest(format=m3u8-cmaf)')}>
                      Lighting configuration</p>                         
                    </>
                  ) : (
                    <>
                    <p>Expand to view all videos</p>
                    </>
                  )}
                </div>

              </div>                    

              <div className="flex-item pill2">
        
                <div id="video-container2">
                  <video id="vid2" className="azuremediaplayer amp-big-play-centered amp-default-skin" width="100%" height="auto" poster="/public/space.jpg" data-setup='{"logo": { "enabled": false }, "fluid": true, "nativeControlsForTouch": false, "autoplay": true, "controls" : true, "loop": false, "muted" : false}'>
                    <source id="vidSrc" src="//x82video-usea.streaming.media.azure.net/39f954bd-cb1f-4473-886b-f35c0c997866/pitchYaGame.ism/manifest(format=m3u8-cmaf)" type="application/vnd.ms-sstr+xml"/>
                    <p className="amp-no-js">
                      To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
                    </p>
                  </video>
                </div>
              </div>
            </div>
        </div>
        <br/>
        <Footer />

      </div>

  );
}

export default Videos;
