import React from 'react';

import '../App.css';
import Header from '../Header';
import Footer from '../Footer';


function SketchFabAuth()
{
    const [code, setResponse] = React.useState("...");
    const [requestState, setRequestState] = React.useState(0);
    console.log("h=" + window.location.search);
    const queryParams = new URLSearchParams(window.location.hash.replace("#","?"))
    let token = queryParams.get("access_token");
    let expires = queryParams.get("expires_in");
    console.log("t=" + token);
  
    const getDataFromApi = async(token: string)=>{
      
      const data = await fetch('/api/skfauth?code=' + token + "&expires=" + expires);
      
      if (data != null)
      {
        let text = await data.text();
        if (text !== "") {
          let code = JSON.parse(text).code;
          setResponse(code);
          setRequestState(2);
        }
        else 
          setRequestState(3);
      }
      
    };

    var state="";
    if (token === null)
      state="invalid request";
    else
    {
      switch (requestState) {
        case 0:
          state="Requesting code";
          setRequestState(1);
          getDataFromApi(token);
          break;
        case 1:
          state="Requested code";
          break;
        case 2:
          state="Code";
          break;
        case 3:
          state="Failed to get code";
          break;          
      }
    }

 
    
  
    return (
        <div>

            <Header showHeader="1" />

            <h2>Sketchfab Authorization</h2>
            <div className="flex-container">
                <div className="flex-item pill1">
                <div>
                    <p><strong>Authorization completed</strong></p>
                    <p>Enter the following code in Idea Engine to enabled your Sketchfab account to be used in the app</p>
                </div>
                </div>
                <div className="flex-item pill2">
                <div>
                    <center>
                    <p><strong>{state}</strong></p>
                    <p>
                        <span className='theCode'>{code}</span>
                    </p>
                    <p>{requestState===2?"Must be entered within 5 minutes":""}</p>
                    </center>
                </div>
                </div>      
            </div>
            <br/>
            <br/>
            <br/>
            <Footer />
        </div>       
    );
}

export default SketchFabAuth;