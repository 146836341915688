import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function Privacy() {
  
  return (
      <div>
       
        <Header showHeader="1" />
        
        <NavBar path="/xr_privacy" />

        <div id="infoPage">

          <h2>Idea Engine / FastHands - Privacy</h2>
          
          <p>Updated 09 September 2024</p>
        
          <p>The term &#39;us&#39;, &#39;our&#39; or &#39;we&#39; refers to the owner of the software trading as X82 Ltd. The term &#39;you&#39; refers to the customer / user of our software.</p>
          <p>We are committed to ensuring that your privacy is protected. You can be assured that any information we hold will only be used in accordance with this privacy statement.</p>
          <p>We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
          
          <div>       
            <h3>What we collect</h3>
            <p>We may collect the following information in our software:<br/>
                1) username, user id, device id.<br/>
                2) email (if provided).<br/>
                3) projects and their assets that you choose to publically share online.<br/><br/>
            </p>
            <p>
            Our applications may collect data such as your username, user id, profile information and avatar details. This information is used locally in the application to provide your experience.
            </p>
            <p>
            If you contact us directly for support we may store your contact information (name, email) and details you provide to us (devices owned, problem reports).
            </p>            
          </div>

          <div>
            <h3>Data Usage</h3>
            <p>User ids and device ids are stored as a one-way hash which means we don't have access to and can't recreate the original data. Such information is used for logging in and associating any project assets you choose to upload.</p>
            <p>Email addresses are encrypted and used if it is necessary to contact you regarding your projects.</p>
            <p>Projects and their assets that you share online will be publically available to other users (credited to your user name) so you must not include personal information.</p>
            <p>Offensive projects will be removed and may lead to the uploading user and their devices being blocked from using the software.</p>
            <p>You must only upload assets that you have personally created or where a valid licence is avalable (CC0, CC-BY etc.)</p>
          </div>

          <div>
            <h3>Children's Privacy</h3>
            <p>
            Our applications do not collect any personal data from children under the age of 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.          
            </p>
          </div>
          
          <div>
              <h3>Controlling your personal information</h3>
              <p>We will not distribute any information about you to third parties unless we are required by law to do so.</p>
              <p>If you have any concerns about your personal information, email us at support@X82hub.com</p>
              <p>If you wish to delete your personal information, email us at support@X82hub.com</p>
          </div>

          
        </div>
        <br/>
        <Footer />
      </div>

  );
}

export default Privacy;
