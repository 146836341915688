import './App.css';

function Header(props: { showHeader: any; })
{
  
    return (
        <header id="header">
        <h1>
            {props.showHeader === "1"  &&
                <div>
                    <a href="/"><img id="logo" src="/logoTPx300x171.png" alt="X82 Logo"/></a>
                    <img id="mBadge" src="MS_Startups_Celebration_Badge_Light.png" alt="Proud to partner with Microsoft for Startups"/>
                </div>
            }
        </h1>
        <div className="clear"></div>
      </header>        
    );
}

export default Header;