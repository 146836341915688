import React from 'react';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

import '../App.css';


function NotFound() {

    
  return (
    <div className="App">
    
      <Header showHeader="1" />

      <NavBar path="/unknown" />
      <br /><br />
      <center>
        <h2>Ooops. Looks like you're lost</h2>
      </center>
      <br /><br /><br /><br />
      
      <Footer />

      <div id="thePage">
        <div className="clear"></div>
        Not Found
      </div>

    </div>
  );
}

export default NotFound;
