import '../App.css';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';

interface RouteParams {
  videoId: string;
}


declare global {
  interface Window {
    amp: any; // Change `any` to the actual type definition of the `amp` library
  }
}

// Function to update the video URL
const getUpdatedVideoUrl = (videoId: string): string => {
  var videoUrl="";
  
  switch (videoId)
  {
    case "1": // Hand menu & locomotion
      videoUrl = "https://x82video-usea.streaming.media.azure.net/e8a59c09-41da-49b7-b153-b98ea1104646/Vol1_handMenuAndLocomotion.mp4.ism/manifest(format=m3u8-cmaf)";
      break;
    case "2": // Add nodes
        videoUrl = "https://x82video-usea.streaming.media.azure.net/aa829846-c184-40ce-adc5-f568f893e77c/vol1_addNodes.mp4.ism/manifest(format=m3u8-cmaf)";
        break;      
    case "3": // Locations
        videoUrl = "https://x82video-usea.streaming.media.azure.net/e248b664-07b6-44e7-8edf-27e99117524d/vol1_locations.mp4.ism/manifest(format=m3u8-cmaf)";
        break;              
    case "4": // States
        videoUrl = "https://x82video-usea.streaming.media.azure.net/9b2776eb-c51e-4393-aec4-754c1a5131f4/vol1_states.mp4.ism/manifest(format=m3u8-cmaf)";
        break;                      
    case "5": // Events
        videoUrl = "https://x82video-usea.streaming.media.azure.net/99646046-a32b-4dc7-b3a8-1c3f2efea146/vol1_events.mp4.ism/manifest(format=m3u8-cmaf)";
        break;                      
  }
  
  return videoUrl;
};

function Video() {

  const { videoId } = useParams<RouteParams>();

  if (videoId === undefined) {
    // Handle the case when videoId is undefined, e.g., redirect or show an error message
    return <div>Error: Video ID is undefined</div>;
  }

  const updatedVideoUrl = getUpdatedVideoUrl(videoId);

  return (
      <div>
        <Helmet>
          <link rel="stylesheet" href="//amp.azure.net/libs/amp/2.3.11/skins/amp-default/azuremediaplayer.min.css" type="text/css" />
          <script src= "//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js" type="text/javascript"/>
        </Helmet>     
        <div id="thePage">
          <center>
            <div id="videoPage">
              <video id="vid3" className="azuremediaplayer amp-big-play-centered amp-default-skin" width="100%" height="auto" poster="/public/space.jpg" data-setup='{"logo": { "enabled": false }, "fluid": true, "nativeControlsForTouch": false, "autoplay": true, "controls" : true, "loop": false, "muted" : false, "fullscreen" : true}'>
                <source id="vidSrc" src={updatedVideoUrl}/>
                <p className="amp-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
                </p>
              </video>
            </div>
            <h2 id="videoTitle">Click the DONE button above to return to Idea Engine</h2>
          </center>
        </div>

      </div>

  );
}

export default Video;
