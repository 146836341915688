import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function FastHands() {
  
  return (
      <div>
       
        <Header showHeader="1" />
        
        <NavBar path="/fasthands" />

        <div id="infoPage">
          <center>
            <div id="fastHandsLogo">
              <img src={`${process.env.PUBLIC_URL}/1080x360.png`} alt="FastHands" />
            </div>
          </center>

          <h2>FastHands alpha / beta access program</h2>
        
          <p>Alpha access is currently available on the Meta Quest</p>
          <p>Join our <a href="https://discord.gg/CcrCx76BRp">FastHands Discord channel</a> 
          for details on how to access the game and provide feedback.
            
            </p>
          
          
        </div>
        <br/>
        <Footer />
      </div>

  );
}

export default FastHands;
