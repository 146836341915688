import '../App.css';
import React, { useEffect } from 'react';

declare global {
  interface Window {
    amp: any; // Change `any` to the actual type definition of the `amp` library
  }
}


function RPM() {
  const [isButtonVisible, setIsButtonVisible] = React.useState(true);
  const [isIframeComplete, setIsIframeComplete] = React.useState(false);

  const subdomain = 'x82'; // Replace with your custom subdomain

  useEffect(() => {
    const frame = document.getElementById('frame') as HTMLIFrameElement;

    if (frame) {
      frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;

      const subscribe = (event: MessageEvent) => {
        const json = parse(event);

        if (json?.source !== 'readyplayerme') {
          return;
        }

        // Subscribe to all events sent from Ready Player Me once the frame is ready
        if (json.eventName === 'v1.frame.ready') {
          frame.contentWindow?.postMessage(
            JSON.stringify({
              target: 'readyplayerme',
              type: 'subscribe',
              eventName: 'v1.**',
            }),
            '*'
          );
        }

        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
          console.log(`Avatar URL: ${json.data.url}`);
          //document.getElementById('avatarUrl')!.innerHTML = `Avatar URL: ${json.data.url}`;
          document.getElementById('frame')!.hidden = true;
          setIsIframeComplete(true);
        }

        // Get user id
        if (json.eventName === 'v1.user.set') {
          console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
        }
      };

      window.addEventListener('message', subscribe);

      return () => {
        window.removeEventListener('message', subscribe);

      };
    }
  }, [subdomain]);

  const parse = (event: MessageEvent): any => {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  };

  const displayIframe = () => {
    setIsButtonVisible(false);
    document.getElementById('frame')!.hidden = false;
  };

  return (
      <div>
              {isButtonVisible && 
              <center>
                <br/>
                <br/>
                <input className="myBtn" type="button" value="Open Ready Player Me" onClick={displayIframe} />
              </center>}
              {isIframeComplete && 
              <center>
                <p id="avatarUrl">Avatar URL:</p>
                <h2>Avatar Creation Complete<br/><br/>Click the DONE button above to close this browser</h2>
              </center>
              }
              <iframe id="frame" className="rpmFrame" title="Ready Player Me" allow="camera *; microphone *; clipboard-write" hidden></iframe>

      </div>

  );
}

export default RPM;
