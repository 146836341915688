import './App.css';

function Footer()
{
 
    return (
        <div id="footer">
            
            <div>
                <a href="privacy">Privacy</a>
                
                <span>
                <a href="https://twitter.com/JumbliVR"><i className="fa-brands fa-twitter"></i></a>
                <a href="https://www.youtube.com/playlist?list=PLuy0o1eHIdSQmz4X-gdxMnTW1pL138xI7"><i className="fa-brands fa-youtube"></i></a>
                <a href="https://www.instagram.com/jumblivr/"><i className="fa-brands fa-instagram"></i></a>
                <a href="https://www.tiktok.com/@jumblivr"><i className="fa-brands fa-tiktok"></i></a>
                <a href="https://www.linkedin.com/company/x82/"><i className="fa-brands fa-linkedin-in"></i></a>
                </span> 

                <a href="https://www.x82hub.com/contact">X82 Ltd</a>                
                <br/>
                
            </div>
        </div>   
    );
}

export default Footer;