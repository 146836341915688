import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function IdeaEngine() {

  return (
      <div>
       
        <Header showHeader="1" />
        
        <div id="thePage">

          <NavBar path="/ideaengine" />


            <div className="flex-container">
              <div className="flex-item pill1">
                <h3>Idea Engine</h3>
                <p><strong>
                  Get Idea Engine on: <a href="https://www.oculus.com/experiences/quest/8721586871245581/">Meta Quest</a>
                  </strong>
                </p>
                <p>
                Create interactive stories, games, experiences and educational mind maps in VR and mixed reality to share with your friends.
                Load custom models, images and sounds to create interactive, non-linear stories, 
                  games or training scenarios.
                  </p>
                  <p>An early prototype won the <a className="urlIcon" href="https://devpost.com/software/unnamed-94mkf0">Mixed Reality Challenge: StereoKit.</a></p>

                  <p><b>For consumer and business users.</b></p>
                  <p>
                    Quickly prototype your project with a mind map, showing entities and relationships.
                    Expand your prototype to add assets, events and scripts to create an interactive experience.
                  </p>

                  <h3>X82 metaverse</h3>
                  <p>Claim an X82 metaverse address and upload your project to it.</p>
                  <p>Browse the X82 metaverse to discover shared creations.</p>

                  
              </div>                    

              <div className="flex-item pill2">
                <h3>Features</h3>
                <p>Supports mixed reality (MR) and virtual reality (VR).</p>
                <p>Use AI to generate text and images.</p>
                <p>Voice &lt;-&gt; text.</p>
                <p>Verbally chat to AI characters.</p>
                <p>Inbuilt inventory system.</p>
                <p>Use controllers or hand tracking.</p>
                <p>Upload assets or download from Sketchfab.</p>
                <p>Use high-level, event-driven scripting for interactivity.</p>
                <p>Eye / gaze tracking supported.</p>
                <p>Multiplayer coming soon.</p>
              </div>
            </div>
        </div>
        <br/>
        <Footer />

      </div>

  );
}

export default IdeaEngine;
