import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function Privacy() {
  
  return (
      <div>
       
        <Header showHeader="1" />
        
        <NavBar path="/privacy" />

        <div id="infoPage">

          <h2>Privacy</h2>
        
          <p>The term 'us', 'our' or 'we' refers to the owner of the website trading as X82 Ltd. The term 'you' refers to the customer, user or viewer of our website.</p>
          <p>We are committed to ensuring that your privacy is protected. You can be assured that any information we hold will only be used in accordance with this privacy statement.</p>
          <p>We may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01 January 2023.</p>
          
          <h3>What we collect</h3>
          <p>This website does not collect personal information.</p>

          <h3>What we do with the information we gather</h3>
          <p>This website does not collect personal information.</p>
          
          <h3>Controlling your personal information</h3>
          <p>If you have any concerns about your personal information, email us at support@X82Hub.com</p>

          <h3>How we use cookies</h3>
          
          <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
          <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
          <p>Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
          <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
          
          <h3>Links to other websites</h3>
          
          <p>Our website may contain links to other websites. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
          
        </div>
        <br/>
        <Footer />
      </div>

  );
}

export default Privacy;
