import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function Privacy() {
  
  return (
      <div>
       
        <Header showHeader="1" />
        
        <NavBar path="/contact" />

        <div id="infoPage">

          <h2>Idea Engine - Assets</h2>
                
          <p>You must only add assets to Idea Engine projects that you intent to share under the following conditions:</p>
          <ul>
            <li>You created the asset, or </li>
            <li>The asset has a licence that allows it to be shared for commercial use, e.g. CC-0, CC-BY.</li>
            <li>You enter the licence details (author / source etc)</li>
            <li>The asset does not infinge on anyones IP (e.g. no Star Wars characters).</li>
            <li>The asset is not offensive.</li>
          </ul>
          <p>You are responsible for the assets you upload and may have your content removed and access blocked if it brakes our rules.</p>
          <p>In short, play fair and be nice.</p>

          
          <h3>Models</h3>
          <p>You can use the inbuilt Sketchfab search in Idea Engine.</p>

          <h3>360 degree backgrounds</h3>
          <p>Background images should be no more than 4096px (wide) x 2048px (high).</p>
          <p><a href="https://www.flickr.com/search/?text=Equirectangular&advanced=1&media=photos&dimension_search_mode=min&height=2048&width=4096&sort=interestingness-desc&orientation=panorama%2Clandscape&l=4">Flickr</a> - make sure you use those a CC-BY licence (some rights reserved)</p>
          <p><a href="https://polyhaven.com/hdris">Polyhaven</a> - you will need to edit the downloaded file and save as a .jpg.</p>

          <h3>Audio</h3>
          <p><a href="https://freepd.com/">Freepd</a> - Wide selection of music tracks.</p>
          <p><a href="https://alkakrab.itch.io/">Alkakrab</a> -  offers a lot of free music tracks.</p>
          <p><a href="https://freesound.org/">Freesound</a> - sound effects.</p>

          <h3>How to import into Idea Engine</h3>
          <p>Use the [Import] option in the hand menu or the [import] button when browsing assets.</p>
          <p>You will then be able to access your VR headset via a web browser on your phone or computer and upload the assets.</p>
        </div>
        <br/>

        <Footer />
      </div>

  );
}

export default Privacy;
