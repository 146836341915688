import React, { useRef, useEffect, useState } from 'react';
import Header  from '../Header';
import '../App.css';
import Footer  from '../Footer';
import NavBar  from '../NavBar';
import { Helmet } from 'react-helmet';



function Home() {

  const bottomContentRef = useRef<HTMLDivElement>(null);
  const [bottomContentHeight, setBottomContentHeight] = useState(0);

  useEffect(() => {
    const calculateBottomContentHeight = () => {
      if (bottomContentRef.current) {
        const height = bottomContentRef.current.offsetHeight;
        setBottomContentHeight(height);
      }
    };

    calculateBottomContentHeight(); // Initial calculation

    window.addEventListener('resize', calculateBottomContentHeight);

    return () => {
      window.removeEventListener('resize', calculateBottomContentHeight);
    };
  }, []);

  return (
      <div>
        <Helmet>
          <link rel="stylesheet" href="//amp.azure.net/libs/amp/2.3.11/skins/amp-default/azuremediaplayer.min.css" type="text/css" />
          <script src= "//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js" type="text/javascript"/>
        </Helmet>        
        
        <div id="video-container" style={{ marginTop: -bottomContentHeight / 2, bottom: bottomContentHeight }}>
          <video id="vid1" className="azuremediaplayer amp-default-skin" width="auto" height="100%" poster="poster.jpg" 
          data-setup='{"heuristicProfile": "LowLatency", "logo": { "enabled": false }, "nativeControlsForTouch": false, "autoplay": true, "controls" : false, "loop": true, "muted" : true}'>
            <source src="//x82video-usea.streaming.media.azure.net/73881b07-b3a5-48cc-b4d8-de5ba3a25af8/webIntro.ism/manifest(format=m3u8-cmaf)" type="application/vnd.ms-sstr+xml" />
            <p className="amp-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
            </p>
          </video>
        </div>

        <Header showHeader="1" />

        <div ref={bottomContentRef} id="content">
          <NavBar path="/" />
          <div id="paddedContent">
            <h1>Everyone has a story</h1>
            <p>Create and share your interactive stories, games and experiences with <span className="highlight">Idea Engine</span>.
              <br/>
            Download the alpha version today and join our adventure.</p>
          </div>

          <Footer />
          
       </div>
      </div>

  );
}

export default Home;
