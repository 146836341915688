import '../App.css';
import Header  from '../Header';
import Footer  from '../Footer';
import NavBar  from '../NavBar';

function Privacy() {
  
  return (
      <div>
       
        <Header showHeader="1" />

        <div id="thePage">
        
          <NavBar path="/contact" />

          <div className="flex-container">
            <div className="flex-item pill1">
              <h3>Contact / issues</h3>
              
              <p>Please let us know about bugs, improvements or new features you would like to see.</p>
              <hr/>
              <p>Chat to us in the <a href="https://discord.gg/pEaJsd9a93">Idea Engine discord</a> or <a href="https://discord.gg/CcrCx76BRp">FastHands discord</a></p>
              <p>Connect with us on <a href="https://twitter.com/JumbliVR">Twitter</a>, 
                <a href="https://www.threads.net/@jumblivr">Threads</a>, 
                <a href="https://staging.bsky.app/profile/jumblivr.com">Bluesky</a>
              </p>
              <p>or email support@x82hub.com</p>
              <hr/>
              <p>We are busy adding lots of feature and improvements so please be patient with fixes and responses.</p>
    
            </div>

            <div className="flex-item pill2">
              <h3>X82 Ltd</h3>
        
              <p>
                130 West Crescent,<br/>
                Stocksbridge,<br/>
                Sheffield,<br/>
                S36 1GA, UK<br/>
                <br/>
                <sup>
                X82 Ltd is a company registered in England and Wales<br/> with company number 14564128
                </sup>
              </p>
              <h3>Contacts</h3>
              <p>
                Brett Jackson - Director,<br/>
                Kelly Jackson - Director
              </p>
              <p>Connect with us<a href="https://www.linkedin.com/company/x82/">X82 Ltd on LinkedIn</a></p>
            </div>
          </div>
        </div>        
        <br/>
        <Footer />
      </div>

  );
}

export default Privacy;
